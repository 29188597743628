import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  APPS,
  BUS_RECO,
  BUSINESS_SCORE,
  BUSINESSES_TAG,
  CATEGORIES_TAG,
  COURSES_TAG,
  CRD_SCORE,
  CREDIT_RECO,
  CREDIT_SCORE,
  EVENTS_TAG,
  EXPERTS_TAG,
  FAQS_TAG,
  LESSONS_TAG,
  LOAN_PRODUCTS,
  LOAN_TRANSACTIONS,
  LOANS_TAG,
  MARKET_TAG,
  OPTIONS,
  POSTS,
  PROGRAMS,
  QUESTIONS,
  ROLES,
  SMT_CATEGORY,
  SMT_TYPE,
  SURVEY_FORM,
  SURVEY_RESULTS,
  TICKETS_TAG,
  VENDORS,
} from '../../../constants';
import { logOut, setAccessToken } from '../../features/auth/authSlice';

const baseUrl = process.env.REACT_APP_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api.melaninkapital.com',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    result?.error?.status === 'PARSING_ERROR' &&
    result?.error?.originalStatus === 403
  ) {
    const refreshResult = await baseQuery('/refresh', api, extraOptions);
    if (refreshResult?.data) {
      api.dispatch(setAccessToken(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};
export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  tagTypes: [
    COURSES_TAG,
    LESSONS_TAG,
    CATEGORIES_TAG,
    BUSINESSES_TAG,
    TICKETS_TAG,
    LOANS_TAG,
    LOAN_PRODUCTS,
    LOAN_TRANSACTIONS,
    SURVEY_FORM,
    SURVEY_RESULTS,
    POSTS,
    ROLES,
    FAQS_TAG,
    EXPERTS_TAG,
    MARKET_TAG,
    VENDORS,
    APPS,
    PROGRAMS,
    QUESTIONS,
    OPTIONS,
    CREDIT_RECO,
    BUS_RECO,
    CREDIT_SCORE,
    BUSINESS_SCORE,
    CRD_SCORE,
    SMT_TYPE,
    SMT_CATEGORY,
    EVENTS_TAG,
    'BIZ',
    'USER',
  ],
});
