import { apiSlice } from '../../app/api/apiSlice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    getUser: build.query({
      query: () => '/user/profile',
      transformResponse(response) {
        return response?.response;
      },
      providesTags: [{ type: 'USER', id: 'id' }],
    }),
    updateProfile: build.mutation({
      query: payload => {
        const data = new FormData();
        for (const itemKey in payload.item) {
          data.append(itemKey, payload.item[itemKey]);
        }
        return {
          url: `user/update/${payload.id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [{ type: 'BIZ', id: 'id' }],
    }),
    assignRole: build.mutation({
      query: payload => {
        const data = new FormData();
        data.append('attach_role', payload.key);
        return {
          url: `/assign/user/role/${payload.id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    logOut: build.query({
      query: () => `/user/logout`,
    }),
    getUserDetails: build.query({
      query: id => `/user/${id}`,
      transformResponse(response) {
        return response?.response;
      },
      providesTags: [{ type: 'BIZ', id: 'id' }],
    }),
    deleteDocument: build.mutation({
      query: ({ isUser, fileId, modelId }) => {
        let url = '';
        if (isUser === 'user') {
          url = `user/documents/${modelId}/${fileId}`;
        } else if (isUser === 'business') {
          url = `business/documents/${modelId}/${fileId}`;
        } else if (isUser === 'financial') {
          url = `financial/statement/${fileId}`;
        }
        return {
          url,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'BIZ', id: 'id' }],
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateProfileMutation,
  useAssignRoleMutation,
  useLogOutQuery,
  useGetUserDetailsQuery,
  useDeleteDocumentMutation,
} = userApiSlice;
